import { MappingType } from '../api/types/mapper';
import { SourceDetails, SourceId } from '../types';

export const sourcesDetails: Record<SourceId, SourceDetails> = {
    [SourceId.NCAA]: {
        label: 'NCAA',
        mappingTypes: [MappingType.SCHEDULE, MappingType.PLAYER],
    },
    [SourceId.ESPN]: {
        label: 'ESPN',
        mappingTypes: [MappingType.SCHEDULE, MappingType.PLAYER],
    },
    [SourceId.SR]: {
        label: 'Sportradar',
        mappingTypes: [],
    },
    [SourceId.TeamSites]: {
        label: 'Team Sites',
        mappingTypes: [MappingType.SCHEDULE, MappingType.PLAYER],
    },
    [SourceId.NFLGSIS]: {
        label: 'NFL GSIS',
        mappingTypes: [MappingType.SCHEDULE, MappingType.PLAYER],
    },
    [SourceId.AWAY]: {
        label: 'Away',
        mappingTypes: [],
    },
    [SourceId.HOME]: {
        label: 'Home',
        mappingTypes: [],
    },
    [SourceId.OWN]: {
        label: 'Own site',
        mappingTypes: [MappingType.PLAYER],
    },
    [SourceId.OPP]: {
        label: 'Opp site',
        mappingTypes: [MappingType.PLAYER],
    },
    [SourceId.WMT]: {
        label: 'WMT',
        mappingTypes: [MappingType.SCHEDULE, MappingType.PLAYER],
    },
};
